/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
// @material-ui/icons

import styles from "assets/jss/material-kit-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  });
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <NavLink to="/om">
                <a
                  className={classes.block}
                  style={{
                    color: "#3c4858",
                  }}
                >
                  Om
                </a>
              </NavLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <NavLink to="/kontakt" color="#3c4858">
                <a
                  className={classes.block}
                  style={{
                    color: "#3c4858",
                  }}
                >
                  Kontakt
                </a>
              </NavLink>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <NavLink to="/projekt">
                <a
                  className={classes.block}
                  style={{
                    color: "#3c4858",
                  }}
                >
                  Projekt
                </a>
              </NavLink>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          &copy; {1900 + new Date().getYear()}
          <a
            href="https://martjay93.github.io/"
            className={aClasses}
            target="_blank"
          >
            , by Martin Johansson
          </a>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
};
